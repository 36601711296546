import React from "react"
import TermsAndConditions from "../../legal/TermsAndConditions/TermsAndConditions"
import LayoutContext from "../LayoutContext"
import ContactMenu from "../ContactMenu/ContactMenu"

const SideMenu = () => {
  const layoutContext = React.useContext(LayoutContext)

  return (
    <se-sidemenu>
      <se-sidemenu-item item="Contact">
        <ContactMenu/>
      </se-sidemenu-item>
      <se-sidemenu-item item="Legal">
        <se-block outline={true} color="alternative">
          <TermsAndConditions />
        </se-block>
      </se-sidemenu-item>
      <se-sidemenu-item item="About">
        <se-about
          app-title={layoutContext.applicationName}
          version={layoutContext.applicationVersion}
          image-url={layoutContext.aboutImageUrl}>
        </se-about>
      </se-sidemenu-item>
    </se-sidemenu>
  )
}

export default SideMenu