import axios from "axios"
import { webServiceUrl } from "../configuration"

export default class FirmwareApi {
    getAvailableFirmwares = async (accessToken: string) => {
        return axios.get(`${webServiceUrl}/firmware`, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
    }

    updateFirmware = async (accesToken: string, data: { deviceId: string, firmware: string }) => {
        return axios.put(`${webServiceUrl}/firmware`, data, {
            headers: {
                Authorization: 'Bearer ' + accesToken
            }
        })
    }
}