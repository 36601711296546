// Use Schneider design system
import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './jquery';
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.css";
import '@se/fonts/css/styles.css';
import '@se/icons/css/styles.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.css';
import { Auth0Provider } from "@auth0/auth0-react";

// eslint-disable-next-line
import { defineCustomElements as defineSeComponent, JSX as LocalJSX } from '@se/web-ui/loader'
import polyfill from './polyfill'
import ENVIRONMENT from './ENVIRONNEMENT_CONFIG';
import {pdfjs} from 'react-pdf'

// Configure pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

polyfill();

type StencilToReact<T> = {
  [P in keyof T]?: T[P] & Omit<HTMLAttributes<Element>, 'className'> & {
    class?: string;
  }
}
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace JSX {
    // noinspection JSUnusedGlobalSymbols
    interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {
    }
  }
}
defineSeComponent(window).catch(console.error);

ReactDOM.render(
  <Auth0Provider
    audience='https://ctrl.prosumer.io/'
    domain="se-prosumer.auth0.com"
    scope='read:fleet frontend:read config:read'
    clientId={ENVIRONMENT.AUTH0_CLIENT_ID as string}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider >,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
