import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { webServiceUrl } from "../configuration";

const DetailBox = () => {
  // let { idBox } = useParams();
  // const { getAccessTokenSilently } = useAuth0();
  // const [configurations, setConfigurations] = useState([]);

  // const retrieveConfiguration = async () => {
  //   const accessToken = await getAccessTokenSilently();
  //   return fetch(`${webServiceUrl}/device/${idBox}/configuration`, {
  //     headers: new Headers({ Authorization: "Bearer " + accessToken }),
  //   })
  //     .then(
  //       (response) => (response.ok ? response.json() : []),
  //       (error) => []
  //     )
  //     .then((configurations) => {
  //       const tmp = configurations.map((configuration) => {
  //           try {
  //             configuration.value = JSON.parse(configuration.value);
  //           } catch (e) {}

  //           return configuration;
  //         })
  //       setConfigurations(tmp);
  //     });
  // };

  // useEffect(() => {
  //   retrieveConfiguration();
  // }, [idBox]);

  return (
    // <div className="container">
    //   Detail box {idBox}      
    //   <fieldset id="Configuration">
    //     <legend>Change configuration history</legend>
    //     <table>
    //       <thead>
    //         <tr>
    //           <td>Date</td>
    //           <td>Value</td>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {configurations.reverse().map((configuration) => (
    //           <tr key={configuration.id}>
    //             <td>{configuration.creationDate}</td>
    //             <td>{JSON.stringify(configuration.value, null, 4)}</td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   </fieldset>
    // </div>
    <></>
  );
};

export default DetailBox;
