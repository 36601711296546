import React, { useEffect, useState } from 'react';
import './DialogOpeningTunnel.css';
import If from '../If';

const DialogOpeningTunnel = () => {
    const [ipLoading, setIpLoading] = useState(true)
    const [updateNgrok, setUpdateNgrok] = useState(true)
    const [waitingBox, setWaitingBox] = useState(true)

    useEffect(() => {
        setTimeout(() => setIpLoading(false), 500)
        setTimeout(() => setUpdateNgrok(false), 2000)

    }, [])

    return (
        <se-dialog canBackdrop={false} open={true}>
            <se-dialog-header>Open Tunnel</se-dialog-header>
            <se-dialog-content>
                <div className='d-flex flex-row space-between margin-block'>
                    <div>
                        Retrieving IP
                    </div>
                    <div>
                        <If cond={ipLoading}>
                            <i className="fas fa-spinner fa-spin"></i>
                        </If>
                        <If cond={!ipLoading}>
                            <i className="fas fa-check fa-lg"></i>
                        </If>
                    </div>
                </div>
                <div className='d-flex flex-row space-between margin-block'>
                    <div>
                        Updating Ngrok
                    </div>
                    <div>
                        <If cond={!ipLoading}>
                            <If cond={updateNgrok}>
                                <i className="fas fa-spinner fa-spin"></i>
                            </If>
                            <If cond={!updateNgrok}>
                                <i className="fas fa-check fa-lg"></i>
                            </If>
                        </If>
                    </div>
                </div>
                <div className='d-flex flex-row space-between margin-block'>
                    <div>
                        Waiting for box to open tunnel
                    </div>
                    <div>
                        <If cond={!ipLoading && !updateNgrok}>
                            <If cond={waitingBox}>
                                <i className="fas fa-spinner fa-spin"></i>
                            </If>
                            <If cond={!waitingBox}>
                                <i className="fas fa-check fa-lg"></i>
                            </If>
                        </If>
                    </div>
                </div>
            </se-dialog-content>
        </se-dialog>
    )
}

export default DialogOpeningTunnel