import axios from "axios";
import { webServiceUrl } from "../configuration"

export default class DeviceApi {
    
    getDevices = async (accessToken: string) => {
        try {
            return await axios.get(`${webServiceUrl}/device`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
        } catch(error: any) {
            return error.response
        }
}}