import {useAuth0} from "@auth0/auth0-react";
import _ from 'lodash';
import React from 'react';

import NoSiteBanner from "../NoSiteBanner";
import IP from '../api/ip';
import If from "../components/If";
import {accessToConfigurator, accessToFirmware, accessToSSH} from '../utils/role';
import './Home.css';
import DialogOpeningTunnel from "../components/DialogOpeningTunnel/DialogOpeningTunnel";
import DeviceApi from "../api/device";
import TunnelApi from "../api/tunnel";
import FirmwareApi from "../api/firmware";
import {AxiosResponse} from "axios";

enum ControllerState {
    ALL_STATES = "All States",
    ACTIVE = 'Active',
    WARNING = 'Warning',
    ERROR = 'Error',
    LOST = 'Lost',
    NOT_COMMISSIONED = "Non-commissioned",
}

type State = {
    icon: string;
    color?: ColorState;
}

enum ColorState {
    ALTERNATIVE = "alternative",
    ERROR = "error",
    WARNING = "warning",
    SUCCESS = "success",
    PRIMARY = "primary",
    STANDARD = "standard",
    SECONDARY = "secondary",
}

const stateMap: Record<ControllerState, State> = {
    [ControllerState.ALL_STATES]: { 
        icon: "data_refresh_stroke",
        color: undefined
    },
    [ControllerState.LOST]: {
        icon: "notification_critical",
        color: undefined
    },
    [ControllerState.ERROR]: {
        icon: "notification_error_plain",
        color: ColorState.ERROR
    },
    [ControllerState.WARNING]: {
        icon: "general_danger_full",
        color: ColorState.WARNING
    },
    [ControllerState.ACTIVE]: {
        icon: "notification_ok_stroke",
        color: ColorState.SUCCESS
    },
    [ControllerState.NOT_COMMISSIONED]: { 
        icon: "help", 
        color: undefined
    },
};

enum SshState {
    ENABLE = 'Enable',
    DISABLE = 'Disable'
}

const Home = () => {
    const [devices, setDevices] = React.useState<any[]>([])
    const [httpTunnels, setHttpTunnels] = React.useState<any[]>([])
    const [sshTunnels, setSshTunnels] = React.useState<any[]>([])
    const [firmwareUpdateModal, setFirmwareUpdateModal] = React.useState(false)
    const [deviceSelected, setDeviceSelected] = React.useState<{ id: string }>()
    const [firmwares, setFirmwares] = React.useState<{ version: string }[]>([])
    const [confirmUpdate, setConfirmUpdate] = React.useState(false)
    const [firmwareVersionToUpdate, setFirmwareVersionToUpdate] = React.useState('')
    const [firmwareVersionSelected, setFirmwareVersionSelected] = React.useState(true)
    const [loadingUpdate, setLoadingUpdate] = React.useState(false)
    const [openInfoMessage, setOpenInfoMessage] = React.useState(false)
    const [isUserAuthorised, setIsUserAuthorised] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    const [isOpeningActions, setIsOpeningActions] = React.useState<{ id: string, type: string }[]>([]);
    const [isLoadingAction, setIsLoadingAction] = React.useState(false)
    const [openErrorMessage, setOpenErrorMessage] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const [filterDeviceId, setFilterDeviceId] = React.useState('')
    const [filterSiteName, setFilterSiteName] = React.useState('');
    const [filterVersion, setFilterVersion] = React.useState('');
    const [filterState, setFilterState] = React.useState('');
    const [controllerState, setControllerState] = React.useState(ControllerState.ALL_STATES);
    const [filterHttps, setFilterHttps] = React.useState('');
    const [filterSsh, setFilterSsh] = React.useState('');

    const {getAccessTokenSilently} = useAuth0();

    const ip = new IP();
    const deviceApi = new DeviceApi()
    const tunnelApi = new TunnelApi();
    const firmwareApi = new FirmwareApi();

    const okCount = (): number => {
        return devices ? devices
            .map(item => {
                return item.metadata
            })
            .filter(({lastSeen}) => (Date.now() - (new Date(lastSeen).getTime() || 0)) < 300000)
            .length : 0;
    }

    const status = (lastSeen: string) => {
        if (lastSeen === undefined) {
            return ControllerState.NOT_COMMISSIONED;
        }
        const deltaTime = (Date.now() - (new Date(lastSeen).getTime() || 0)) / 1000;

        if (deltaTime > 6 * 2592000) { // More than 6 x 30 days
            return ControllerState.LOST;
        }
        if (deltaTime > 10 * 60) { // More than 10m
            return ControllerState.ERROR;
        }
        if (deltaTime > 5 * 60) { // More than 5m
            return ControllerState.WARNING;
        }
        return ControllerState.ACTIVE;
    }

    const getTunnel = (deviceId: string, type: string) => {
        if (type === 'http') {
            for (let tunnel in httpTunnels) {
                if (httpTunnels[tunnel].device !== undefined && httpTunnels[tunnel].device === deviceId) {
                    return httpTunnels[tunnel];
                }
            }
        } else if (type === 'ssh') {
            for (let tunnel in sshTunnels) {
                if (sshTunnels[tunnel].device === deviceId) {
                    return sshTunnels[tunnel];
                }
            }
        }
        return undefined;
    }

    const getSShState = (deviceId: string): string => {
        const isSshEnabled = getSshValue(deviceId);
        return isSshEnabled ? SshState.DISABLE : SshState.ENABLE;
    }

    const getSshValue = (deviceId: string): boolean => {
        const sshValue = getTunnel(deviceId, 'ssh');
        if (sshValue === undefined) {
            return false
        } else {
            return !!sshValue;
        }
    }

    const handleHttpRemoteAccess = async (openTunnel: boolean, deviceId: string) => {
        const accessToken = await getAccessTokenSilently();
        const userIp = await retrieveIP()
        return await tunnelApi.openOrCloseHTTPTunnel(accessToken, deviceId, openTunnel, userIp)
    }

    const handleSshRemoteAccess = async (openTunnel: boolean, deviceId: string) => {
        const accessToken = await getAccessTokenSilently();
        const userIp = await retrieveIP()
        return await tunnelApi.openOrCloseSSHTunnel(accessToken, deviceId, openTunnel, userIp)
    }

    const handleClick = async (deviceId: string, type: string, event: any) => {
        setOpenErrorMessage(false)
        event.preventDefault();
        if (type === 'http') {
            const tunnelAlreadyOpen = getTunnel(deviceId, 'http')
            if (!tunnelAlreadyOpen) {
                setIsLoadingAction(true);
            } else {
                setIsOpeningActions([
                    ...isOpeningActions,
                    { id: deviceId, type: type }
                ]);
            }
            event.preventDefault();
            return await handleHttpRemoteAccess(!tunnelAlreadyOpen, deviceId)
                .then((response) => {
                    if (response.status !== 200) {
                        setErrorMessage('Can perform this action : Tunnel already open or no Tunnel to close')
                        setOpenErrorMessage(true)
                        return response
                    }
                    updateHttpTunnels(tunnelAlreadyOpen, deviceId, response)
                    setOpenErrorMessage(false)
                    return response;
                }, (err) => {
                    setErrorMessage(err)
                    setOpenErrorMessage(true)
                })
                .finally(() => {
                    setIsLoadingAction(false)
                    setIsOpeningActions(
                        isOpeningActions.filter(device =>
                            device.id !==  deviceId && device.type !== type
                        )
                    );
                })
        } else if (type === 'ssh') {
            // adding device to loadingDeviceList
            setIsOpeningActions([
                ...isOpeningActions,
                { id: deviceId, type: type }
            ]);
            if (event.target.innerHTML !== "N/A") {
                return await handleSshRemoteAccess(!getSshValue(deviceId), deviceId)
                    .then((response) => {
                        if (response.status === 200) {
                            updateSshTunnels(deviceId)
                        }
                        return response;
                    }).finally(() => {
                        setIsLoadingAction(false)
                        // remove device from loadingDeviceList
                        setIsOpeningActions(
                            isOpeningActions.filter(device =>
                                device.id !==  deviceId && device.type !== type
                            )
                        );
                    })
            }
        } else {
            return undefined;
        }
    }

    const updateSshTunnels = async (deviceId: string) => {
        const copySSHTunel = _.cloneDeep(sshTunnels)
        if (getSshValue(deviceId)) {
            setSshTunnels(_.filter(copySSHTunel, t => t.device !== deviceId))
        } else {
            copySSHTunel.push({
                device: deviceId,
                tunnel: null,
                type: 'ssh'
            })
            setSshTunnels(copySSHTunel)
        }
    }

    const updateHttpTunnels = async (tunnelAlreadyOpen: boolean, deviceId: string, response: AxiosResponse) => {
        const copyHTTPTunnel = _.cloneDeep(httpTunnels)
        if (tunnelAlreadyOpen) {
            setHttpTunnels(_.filter(copyHTTPTunnel, t => t.device !== deviceId))
        } else {
            copyHTTPTunnel.push({
                device: deviceId,
                tunnel: response.data.name,
                type: 'http'
            })
            setHttpTunnels(copyHTTPTunnel)
        }
    }

    const checkAccess = async (status: number) => {
        if ([403, 401].includes(status)) {
            setIsUserAuthorised(false)
        } else {
            setIsUserAuthorised(true)
        }
        setIsLoading(false)
    }

    const fetchDevices = async () => {
        const accessToken = await getAccessTokenSilently();
        const result = await deviceApi.getDevices(accessToken)
        await checkAccess(result.status)
        if (result.data) {
            setDevices(_.sortBy(result.data, 'id'))
        }
    }

    const fetchTunnels = async () => {
        const accessToken = await getAccessTokenSilently();
        const result = await tunnelApi.getTunnels(accessToken)
        if (result.status === 200) {
            if (result.data) {
                setHttpTunnels(result.data.filter((t: { device: string, tunnel: string, type: string }) => t.type === 'http'))
                setSshTunnels(result.data.filter((t: { device: string, tunnel: string, type: string }) => t.type === 'ssh'))
            }
        }
    }

    const fetchFirmwaresVersionAvailable = async () => {
        const accessToken = await getAccessTokenSilently();
        const result = await firmwareApi.getAvailableFirmwares(accessToken);
        setFirmwares(result.data)
        checkAccess(result.status)
    }

    const updateFirmwareVersionForDevice = async (deviceId: string, firmware: string) => {
        const accessToken = await getAccessTokenSilently();
        return await firmwareApi.updateFirmware(accessToken, {deviceId, firmware})
    }

    const formatDate = (dateString: string) => {
        if (dateString) {
            const date = new Date(dateString)

            return date.toLocaleString()
        }
        return dateString
    }

    const openFirmwareUpdateModal = async (device: any) => {
        await fetchFirmwaresVersionAvailable()
        setFirmwareUpdateModal(true)
        setDeviceSelected(device)
    }

    const confirmUpdateFirmware = async () => {
        if (deviceSelected && firmwareVersionToUpdate) {
            setLoadingUpdate(true)

            await updateFirmwareVersionForDevice(deviceSelected.id, firmwareVersionToUpdate).then(() => {
                setOpenInfoMessage(true)
                setTimeout(() => {
                    setOpenInfoMessage(false)
                }, 10000)
                setFirmwareVersionToUpdate('')
                setFirmwareUpdateModal(false)
                setLoadingUpdate(false)
            }, (err) => console.error(err))
        }
    }

    const refreshData = () => {
        Promise.all([
            fetchDevices(),
            fetchTunnels(),
        ]).then(() => {
            if (isUserAuthorised) {
                setTimeout(() => refreshData(), 10000)
            }
        })
    }

    React.useEffect(() => {
        if (isUserAuthorised) {
            refreshData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserAuthorised]);
    
    React.useEffect(() => {
        refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveIP = async () => {
        const result = await ip.getIP();
        return result.data.ip
    }
    
    const getDeviceInLine = (device: any, style: string) => {
        const state = status(device.metadata.lastSeen)
        const color = stateMap[state].color
        const icon = stateMap[state].icon
        return (
            <tr key={device.id} className={`list ${style}`}>
                <td style={{textAlign: 'center'}} 
                    title={`State: ${state} \nLast Seen: ${device.metadata.lastSeen ? device.metadata.lastSeen : 'N/A'}`}>
                    <se-icon color={color} >{icon}</se-icon>
                </td>
                <td>{device.id}</td>
                <td>{device.siteName} </td>
                <td>{(device.manifest.stack?.version) || 'N/A'}</td>
                <td>{(device.manifest.stack && formatDate(device.manifest.stack.lastUpdated)) || 'N/A'}</td>
                <td style={{textAlign: 'center'}}>
                    <se-button
                        disabled={!accessToFirmware(device.role)}
                        color='primary' onClick={() => {
                        openFirmwareUpdateModal(device);
                        setConfirmUpdate(false);
                        setFirmwareVersionSelected(false);
                        setFirmwareUpdateModal(false);
                    }}>Update Firmware
                    </se-button>
                </td>
                <td style={{textAlign: 'center'}}>
                    <If cond={isOpeningActions.some(d => d.id === device.id && d.type === "http")}>
                        <i className="fas fa-spinner fa-spin"></i>
                    </If>
                    <If cond={!isOpeningActions.some(d => d.id === device.id && d.type === "http")}>
                        <div className="d-flex justify-center">
                            {renderConfiguratorButton(device)}
                        </div>
                    </If>
                </td>
                <td style={{textAlign: 'center'}}>
                    <If cond={isOpeningActions.some(d => d.id === device.id && d.type === "ssh")}>
                        <i className="fas fa-spinner fa-spin"></i>
                    </If>
                    <If cond={!isOpeningActions.some(d => d.id === device.id && d.type === "ssh")}>
                        <se-button
                            disabled={!accessToSSH(device.role)}
                            onClick={(e) => handleClick(device.id, 'ssh', e)}
                            size='small'
                            color={getSShState(device.id) === SshState.ENABLE ? 'standard' : 'primary'}>{getSShState(device.id)}
                        </se-button>
                    </If>
                    
                </td>
            </tr>
        )
    }

    const renderConfiguratorButton = (device: { role: string, id: string }) => {
        const tunnelForDevice = getTunnel(device.id, 'http');
        return (
            <>
                {/* <If cond={!isLoadingAction}> */}
                <se-button
                    disabled={!accessToConfigurator(device.role)}
                    size='small'
                    color={tunnelForDevice === undefined ? 'standard' : 'primary'}
                    onClick={(e) => handleClick(device.id, 'http', e)}
                >
                    {tunnelForDevice === undefined ? 'Enable' : tunnelForDevice.tunnel}
                </se-button>
                {tunnelForDevice !== undefined ? (
                    <se-button
                        onClick={() => {
                            window.open(`https://${tunnelForDevice.tunnel}.maintenance.ctrl.prosumer.io`, '_blank')
                        }}
                    >Go to <i className="se-icon">action_new_window</i>
                    </se-button>) : (
                    <></>)}

                {/* </If>
                <If cond={isLoadingAction}>Loading ...</If> */}
            </>)
    };
    return (
        <>
            <If cond={isLoading}>
                <se-loading loading={true}/>
            </If>
            <If cond={!isUserAuthorised && !isLoading}>
                <NoSiteBanner></NoSiteBanner>
            </If>
            <If cond={isUserAuthorised && !isLoading}>
                <div className="container">
                    <fieldset id="devices">
                        <legend>EMA Devices ({okCount()}/{devices ? devices.length : 0})</legend>
                        <table>
                            <thead>
                            <tr>
                                <td>State 
                                    <se-tooltip position="right" class="hydrated" action="hover">
                                        <h6>Controller Last Seen</h6>
                                        <div className="tooltip-content" style={{justifyContent:"left"}}>
                                            <p>Active: less than 5 minutes ago.</p>
                                            <p>Warning: more than 5 minutes ago.</p>
                                            <p>Error: more than 10 minutes ago.</p>
                                            <p>Lost: more than 6 months ago.</p>
                                            <p>Non-commissioned: never seen.</p>
                                        </div>
                                        <se-icon color="secondary" size="nano" style={{paddingLeft: '0.25em'}}
                                            slot="tooltip"
                                            class="icon-nano hydrated">information_circle
                                        </se-icon>
                                    </se-tooltip>
                                    <se-form-field type="select" option="stacked">
                                            <se-dropdown alignment="left" style={{backgroundColor: 'white', margin: 'auto'}}>
                                                <se-button slot="trigger" id="state-filter">{controllerState}</se-button>
                                                <se-list option="dropdown">
                                                    {Object.values(ControllerState).map((state) => (
                                                        <li key={state} style={{listStyleType:"none"}}>
                                                            <se-list-item
                                                                item={state} icon={stateMap[state].icon} icon-color={stateMap[state].color}
                                                                onClick={(e) => {
                                                                    setFilterState(state)
                                                                    setControllerState(state)
                                                                }}>
                                                            </se-list-item>
                                                        </li>
                                                    ))}
                                                </se-list>
                                            </se-dropdown>
                                    </se-form-field>
                                </td>
                                <td>Device ID
                                    <se-form-field option="stacked">
                                        <input style={{margin: 'auto'}} onChange={(e) => {
                                            setFilterDeviceId(e.target.value)
                                        }}/>
                                    </se-form-field>
                                </td>
                                <td>Site Name
                                    <se-form-field option="stacked">
                                        <input style={{margin: 'auto'}} onChange={(e) => {
                                            setFilterSiteName(e.target.value)
                                        }}/>
                                    </se-form-field>
                                </td>
                                <td>Version
                                    <se-form-field type="select" option="stacked">
                                        <select onChange={(e) => setFilterVersion(e.target.value)}
                                                style={{backgroundColor: 'white', margin: 'auto'}}>
                                            <option key={0} value={''}></option>
                                            {
                                                devices ? _(devices).map(device => {
                                                    if (_.has(device, 'manifest.stack')) {
                                                        return device.manifest.stack.version
                                                    }
                                                    return null
                                                }).compact().uniq().sort().map((v) => {
                                                    return <option key={v} value={v}>{v}</option>
                                                }).value() : <></>
                                            }
                                        </select>
                                    </se-form-field>
                                </td>
                                <td>Last Updated</td>
                                <td>Change Firmware</td>
                                <td>Configurator
                                    <se-form-field type="select" option="stacked">
                                        <select onChange={(e) => setFilterHttps(e.target.value)}
                                                style={{backgroundColor: 'white', margin: 'auto'}}>
                                            <option key={0} value={''}></option>
                                            {
                                                devices ? _(devices).map(device => {
                                                    if (getTunnel(device.id, "http")) {
                                                        return getTunnel(device.id, "http")
                                                    }
                                                    return null
                                                }).compact().uniq().sort().map(tunnel => {
                                                    return <option key={tunnel.tunnel}
                                                                   value={tunnel.tunnel}>{tunnel.tunnel}</option>
                                                }).value() : <></>
                                            }
                                        </select>
                                    </se-form-field>
                                </td>
                                <td>SSH
                                    <se-form-field type="select" option="stacked">
                                    <select onChange={(e) => setFilterSsh(e.target.value)}
                                            style={{backgroundColor: 'white', margin: 'auto'}}>
                                        <option key={0} value={''}></option>
                                        {
                                            _(SshState).map(item => {
                                                return item
                                            }).compact().uniq().sort().map((state) => {
                                                return <option key={state}
                                                               value={state}>{state === SshState.ENABLE ? 'Non active' : 'Active'}</option>
                                            }).value()
                                        }
                                    </select>
                                    </se-form-field>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {devices ? devices.filter(device => {
                                let filters = []
                                if (filterState !== '' && filterState !== ControllerState.ALL_STATES) {
                                    filters.push(filterState === status(device.metadata.lastSeen))
                                }
                                if (filterDeviceId !== '') {
                                    return device.id.toLowerCase().indexOf(filterDeviceId.toLowerCase()) !== -1
                                }
                                if (filterSiteName !== '') {
                                    return device.siteName && device.siteName.toLowerCase().indexOf(filterSiteName.toLowerCase()) !== -1
                                }
                                if (filterVersion !== '') {
                                    filters.push(filterVersion === _.get(device, 'manifest.stack.version'))
                                }
                                if (filterHttps !== '') {
                                    filters.push(getTunnel(device.id, "http") ? filterHttps === getTunnel(device.id, "http").tunnel : false)
                                }
                                if (filterSsh !== '') {
                                    filters.push(filterSsh === getSShState(device.id))
                                }

                                if (filters.includes(false)) {
                                    return false
                                }
                                return device
                            }).sort(d => d.id).map((device, index) =>
                                index % 2 === 0 ? getDeviceInLine(device, "dark"): getDeviceInLine(device,"transparent")
                            ) : ''}
                            </tbody>
                        </table>
                    </fieldset>
                </div>
                <se-dialog canBackdrop={false} open={firmwareUpdateModal}>
                    <se-dialog-header>Update firmware</se-dialog-header>
                    <se-dialog-content>
                        <se-form-field label={`Device ID`}>
                            <label style={{marginBottom: '0px'}}>{_.get(deviceSelected, 'id')}</label>
                        </se-form-field>
                        <se-form-field label='Current Firmware version'>
                            <label
                                style={{marginBottom: '0px'}}>{_.get(deviceSelected, 'manifest.stack.version')}</label>
                        </se-form-field>
                        <se-form-field label='Firmware available' type='select'>
                            <select value={firmwareVersionToUpdate} onChange={(event) => {
                                setFirmwareVersionToUpdate(event.target.value)
                                setFirmwareVersionSelected(true)
                            }}>
                                <option value=''></option>
                                {firmwares.map((firmware) =>
                                    <option key={firmware.version} value={firmware.version}>{firmware.version}</option>
                                )}
                            </select>
                        </se-form-field>
                    </se-dialog-content>
                    <se-dialog-footer>
                        <se-button disabled={loadingUpdate} onClick={() => {
                            setFirmwareUpdateModal(false)
                            setFirmwareVersionToUpdate('')
                        }}>Cancel
                        </se-button>
                        {!confirmUpdate ? <se-button color='primary' disabled={!firmwareVersionSelected}
                                                     onClick={() => setConfirmUpdate(true)}>
                            Apply</se-button> : <button className="button confirm" disabled={loadingUpdate}
                                                        onClick={() => confirmUpdateFirmware()}>
                            Confirm</button>}
                    </se-dialog-footer>
                </se-dialog>
                <If cond={isLoadingAction}>
                    <DialogOpeningTunnel
                    ></DialogOpeningTunnel>
                </If>
                <se-snackbar type="information" message='Box will be updated soon ( this can take few minutes )'
                             canClose={true} open={openInfoMessage}/>
                <If cond={openErrorMessage}>
                    <se-snackbar type="error" message={errorMessage}
                                 canClose={true} open={openErrorMessage}/>
                </If>
            </If>
        </>
    )
}

export default Home