import _ from 'lodash'

const ENVIRONMENT = {
    BACKEND_HOST: _.defaultTo(
      // react-envs is installed & used by Dockerfile
    _.get(window, '__react-envs__.BACKEND_HOST'),
    // eslint-disable-next-line
    process.env.REACT_APP_BACKEND_HOST,
  ),
  AUTH0_CLIENT_ID: _.defaultTo(
    // react-envs is installed & used by Dockerfile
    _.get(window, '__react-envs__.AUTH0_CLIENT_ID'),
    // eslint-disable-next-line
    process.env.REACT_APP_AUTH0_CLIENT_ID,
  ),
  VERSION: _.defaultTo(
    // react-envs is installed & used by Dockerfile
    _.get(window, '__react-envs__.VERSION'),
    // eslint-disable-next-line
    process.env.REACT_APP_VERSION,
  )
}

export default ENVIRONMENT
