import ENVIRONMENT from "../ENVIRONNEMENT_CONFIG"

const LAYOUT_CONFIG = {
  applicationName: "Microgrid Maintain",
  applicationVersion: ENVIRONMENT.VERSION,
  aboutImageUrl: "url(https://dsointstorage.blob.core.windows.net/assets/images/background-login.jpg)",
  contactList: [
    {
      label: 'North America',
      email: 'eac_support@schneider-electric.com',
    },
    {
      label: 'Asia and Pacific countries',
      email: 'ema.support@se.com',
    },
    {
      label: 'Other countries',
      email: 'ema.support@schneider-electric.com',
    }
  ]
}

export default LAYOUT_CONFIG