import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './home/Home';
import { useAuth0 } from "@auth0/auth0-react";
import './App.css';
import DetailBox from './DetailBox/DetailBox';
import LAYOUT_CONFIG from './layout/LAYOUT_CONFIG';
import Header from './layout/Header/Header';
import LayoutContext from './layout/LayoutContext';

const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const layoutContext = React.useContext(LayoutContext)

  // Executes on component onMount()
  React.useEffect(() => {
    document.title = layoutContext.applicationName;
  });

  if (isLoading) {
    return <se-loading loading={true} />;
  }

  if (!isAuthenticated) {
    loginWithRedirect().catch((error) => {
      console.error(error);
    });
  }

  return (
    (
      isAuthenticated
        ? <se-app>
          <Router>
            <LayoutContext.Provider value={LAYOUT_CONFIG}>
              <Header logout={() => logout({ returnTo: window.location.origin })}/>
              <se-tabbar color="primary" />
              <div className="app-container">
                {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
                <div className="page-content">
                  <Switch>
                    <Route exact path="/" >
                      <Home></Home>
                    </Route>
                    <Route path="/details/:idBox" >
                      <DetailBox />
                    </Route>
                  </Switch>
                </div>
              </div>
            </LayoutContext.Provider>
          </Router>
        </se-app>
        : <se-loading loading={true} />
    )
  );
};

export default App;