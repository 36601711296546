import axios from "axios";
import { webServiceUrl } from "../configuration"

export default class TunnelApi {
    getTunnels = async (accessToken: string) => {
        try {
            return await axios.get(`${webServiceUrl}/tunnel`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
        } catch (error: any) {
            return error.response
        }
    }

    openOrCloseHTTPTunnel = async (accessToken: string, deviceId: string, openTunnel: boolean, userIP: string) => {
        return await axios.patch(
            `${webServiceUrl}/tunnel/${deviceId}?type=http`,
            { enabled: openTunnel, userIP },
            { headers: { Authorization: 'Bearer ' + accessToken } })
    }

    openOrCloseSSHTunnel = async (accessToken: string, deviceId: string, openTunnel: boolean, userIP: string) => {
        return await axios.patch(
            `${webServiceUrl}/tunnel/${deviceId}?type=ssh`,
            { enabled: openTunnel, userIP },
            { headers: { Authorization: 'Bearer ' + accessToken } })
    }

    testIfTunnelOpen = async (tunnelName: string) => {
        return await axios.get(`https://${tunnelName}.maintenance.ctrl.prosumer.io`)
    }
}