const polyfill = () => {
  if(!Array.prototype.flatMap) {
    // eslint-disable-next-line
    Array.prototype.flatMap = function(fct) {
      // @ts-ignore
      return this.reduce((acc, x) => acc.concat(fct(x)), []);
    }
  }

  // polyfill of Object.fromEntries that does not exist in current version of node (used for tests)
  if(!Object.fromEntries) {
    // eslint-disable-next-line
    Object.fromEntries = function(entries: any[]) {
      return entries.reduce((object, [key, value]) => ({ ...object, [key]: value }), {});
    }
  }
}

export default polyfill;