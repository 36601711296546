import React from 'react'
import _ from 'lodash'
import { Document, Page } from 'react-pdf'
import NoDataMessage from '../../components/NoDataMessage/NoDataMessage'
import './LegalDocument.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

interface LegalDocumentProps {
  file: string
}

const LegalDocument = (props: LegalDocumentProps) => {
  const [pageCount, setPageCount] = React.useState<number>(0)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageCount(numPages);
  }

  const renderPages = () => {
    return _.times(pageCount, (index) => {
      return <Page
        key={index}
        pageIndex={index}
        width={800}
        loading=""
        error={<NoDataMessage/>}
        noData={<NoDataMessage/>}
      />
    })
  }

  return (
    <div className="legal-document">
      <div className="document-container">
        <div className="document">
          <Document
            file={props.file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<se-loading loading={true}/>}
            error={<NoDataMessage/>}
            noData={<NoDataMessage/>}
            // Fix the jagged fonts that appears sometimes in Chrome with canvas mode
            // SVG is deprecated, disabled for now to avoid tons of warning
            renderMode="canvas"
          >
            {renderPages()}
          </Document>
        </div>
      </div>
    </div>
  )
}

export default LegalDocument