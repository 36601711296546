import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

interface UserMenuProps {
  logout: Function
}

const UserMenu = (props: UserMenuProps) => {
  const { user } = useAuth0()

  return (
    <div slot="end">
      <se-dropdown alignment="right">
        <se-button slot="trigger" option="inherit" icon="user_standard" icon-color="primary">{user?.name}</se-button>
        <se-list option="dropdown">
          <se-list-item item="Logout" onClick={() => props.logout()}></se-list-item>
        </se-list>
      </se-dropdown>
    </div>
  )
}

export default UserMenu