enum Role {
    SUPPORT = 'SUPPORT',
    ENGINEER = 'ENGINEER',
    SECADM = 'SECADM',
    VIEWER = 'VIEWER',
    OPERATOR = 'OPERATOR',
    OLD_ROLE = 'OLD_ROLE'
}

const accessToFirmware = (role: string) => {
    switch (role) {
        case Role.SUPPORT:
        case Role.ENGINEER:
        case Role.SECADM:
            return true;
        default:
            return false;
    }
}

const accessToConfigurator = (role: string) => {
    switch (role) {
        case Role.SUPPORT:
        case Role.ENGINEER:
        case Role.SECADM:
            return true;
        default:
            return false;
    }
}

const accessToSSH = (role: string) => {
    if (Role.SUPPORT === role) {
        return true;
    } else {
        return false;
    }
}

export { accessToFirmware, accessToConfigurator, accessToSSH }