interface IfProps {
  cond: boolean;
  children: any;
}

const If = (props: IfProps) => {
  let result = null
  if (props.cond) {
    result = props.children
  }
  return result
}

export default If
