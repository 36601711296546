import React from "react"
import SideMenu from "../SideMenu/SideMenu"
import UserMenu from "../UserMenu"
import LayoutContext from "../LayoutContext"

interface HeaderProps {
  logout: Function
}

const Header = (props: HeaderProps) => {
  const layoutContext = React.useContext(LayoutContext)

  return (
    <se-header app-title={layoutContext.applicationName}>
      <SideMenu/>
      <UserMenu logout={props.logout}/>
    </se-header>
  )
}

export default Header