import React from 'react'

import './NoSiteBanner.css'
import '@se/fonts/css/styles.css'
import '@se/icons/css/styles.css'


const NoSiteBanner = () => {
  return (
    <div className="no-site-banner">
      <h3 className="color-primary">{"Welcome to EcoStruxure Microgrid Maintain"}</h3>
      <h4 className="color-primary">{"You do not have access to any device for the moment."}</h4>
    </div>
  )
}

export default NoSiteBanner
