import React from "react"
import LayoutContext from "../LayoutContext"
import _ from "lodash"

const ContactMenu = () => {

  const layoutContext = React.useContext(LayoutContext)

  const renderContactList = () => {
    return _.map(layoutContext.contactList, (contact, index) => (
      <se-block>
        <se-block-header>{contact.label}</se-block-header>
        <se-block-content>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </se-block-content>
      </se-block>
    ))
  }

  return (
    <se-container display="block" option="card" direction="column">
      {renderContactList()}
    </se-container>
  )
}

export default ContactMenu